import React, { useEffect, useState, useMemo } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import StoryblokComponent from "../components/storyblok/storyblok-component";
import StoryblokArticleComponent from "../components/storyblok/storyblok-article-component";
import StoryblokArticleRootPage from "../components/storyblok/storyblok-article-root-page";
import StoryblokShopComponent from "../components/storyblok/storyblok-shop-component";

import SharingImage from "../assets/images/standardSharingImage.jpg";

function StoryblokPage({ pageContext }) {
  const content = useMemo(() => {
    const { story } = pageContext;
    const parsedContent = JSON.parse(story.content);
    return {
      ...parsedContent,
      firstPublishedAt: story.first_published_at,
      pageName: parsedContent.pageName || parsedContent.pageTitle || story.name,
    };
  }, [pageContext.story.uuid]);

  function selectPageComponent(componentName) {
    switch (componentName) {
      case "articleRoot":
        return StoryblokArticleRootPage;
      case "article":
        return StoryblokArticleComponent;
      case "shop":
        return StoryblokShopComponent;
      case "page":
        return StoryblokComponent;
      default:
        return null;
    }
  }

  function renderPageContent() {
    if (!(content || content.body || content.body.length)) {
      return null;
    }

    const StoryblokPageComponent = selectPageComponent(content.component);

    return StoryblokPageComponent ? (
      <StoryblokPageComponent {...content} pageContext={pageContext} />
    ) : (
      <div>Unknown storyblok component</div>
    );
  }

  if (!content) {
    return null;
  }

  let pageTitle = "Leeds United Supporters' Trust";
  let pageDesc =
    "The Leeds United Supporters' Trust is a democratic, non-for-profit organization, which believe that as fans, we're stronger together.";
  let pageSharingImage = "https://www.lufctrust.com" + SharingImage;

  if (content.seoTitle !== "" || content.seoTitle !== undefined) {
    if (content.seoTitle !== "Home" && content.seoTitle !== undefined) {
      pageTitle = content.seoTitle + " | Leeds United Supporters' Trust";
    }
  }

  if (content.seoSharingImage && content.seoSharingImage.filename !== null) {
    pageSharingImage = content.seoSharingImage.filename;
  } else if (
    content.socialSharingImage &&
    content.socialSharingImage.filename !== null
  ) {
    pageSharingImage = content.socialSharingImage.filename;
  } else {
    pageSharingImage = "https://www.lufctrust.com" + SharingImage;
  }

  let articleMode = false;

  if (content.component === "article") {
    pageTitle = content.pageName;
    pageDesc = content.description;

    articleMode = true;
  }

  let articleRootMode = false;

  if (content.component === "articleRoot") {
    articleRootMode = true;
  }

  return (
    <>
      <Seo
        title={pageTitle}
        pageDesc={pageDesc}
        sharingImage={pageSharingImage}
      />
      <Layout
        title={pageTitle}
        description={pageDesc}
        sharingImage={pageSharingImage}
        articleMode={articleMode}
        articleRootMode={articleRootMode}
      >
        {renderPageContent()}
      </Layout>
    </>
  );
}

export default StoryblokPage;
